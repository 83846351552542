<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <input-text
        v-model="form.descricao"
        :label="$t('ACADEMICO.NOME')"
        :placeholder="$t('ACADEMICO.NOME')"
        type="text"
        :maxLength="50"
        ref="nome"
        required
      />
    </div>
    <div class="col-12 col-md-4">
      <input-text
        type="number"
        v-model="form.cargaHoraria"
        :label="$t('ACADEMICO.CARGA_HORARIA')"
        :placeholder="$t('ACADEMICO.CARGA_HORARIA')"
        :min="1"
        :maxLength="6"
        ref="cargaHoraria"
        required
      />
    </div>
    <div class="col-12 col-md-4">
      <input-text
        type="number"
        v-model="form.vagas"
        :label="$t('ACADEMICO.VAGAS')"
        :placeholder="$t('ACADEMICO.VAGAS')"
        :min="1"
        :maxLength="6"
        ref="vaga"
        required
      />
    </div>
  </div>
</template>
<script>
import { InputText } from '@/components/inputs';
export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputText,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
};
</script>
