<template>
  <b-table
    :items="items"
    :fields="fields"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    responsive
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="excluir(item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
export default {
  name: 'TabelaDisciplina',
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'codigo',
          label: 'Disciplina',
          sortable: true,
        },
        {
          key: 'disciplinaDescricao',
          label: 'Descrição',
          sortable: true,
        },
        {
          key: 'tipoMediaEnumDescricao',
          label: 'Média',
          sortable: true,
        },
        {
          key: 'cargaHoraria',
          label: 'Carga horária',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    excluir(item) {
      this.$emit('remover', item);
    },
  },
};
</script>
