<template>
  <div class="row">
    <div class="col-md-3">
      <input-select-search
        ref="disciplina"
        v-model="disciplina.disciplinaId"
        :label="'Disciplina'"
        :options="opcoes.disciplina"
        required
      />
    </div>
    <div class="col-md-3">
      <input-select-search
        ref="media"
        v-model="disciplina.tipoMediaEnum"
        :label="'Média'"
        :options="opcoes.media"
        required
      />
    </div>
    <div class="col-md-3">
      <input-text
        type="number"
        ref="cargaHoraria"
        v-model="disciplina.cargaHoraria"
        :label="'Carga Horária'"
        :min="1"
        required
      />
    </div>
    <div class="col-md-3 alinhamento-botao">
      <b-button variant="primary" @click="adicionar">Adicionar</b-button>
    </div>
    <tabela-disciplina :items="disciplinas" @remover="remover" />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
// import TurmaCurriculo from '@/common/services/curriculo-escolar/turma.service'
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import { InputSelectSearch, InputText } from '@/components/inputs';
import TabelaDisciplina from './TabelaDisciplina';
import disciplinasService from '@/common/services/academico/disciplinas.service.js';
import helpers from '@/common/utils/helpers';

export default {
  name: 'FormDisciplina',
  components: { InputText, InputSelectSearch, TabelaDisciplina },
  data() {
    return {
      opcoes: {
        disciplina: [],
        media: [],
      },
      disciplinas: [],
      disciplina: { disciplinaId: '', tipoMediaEnum: '', cargaHoraria: '' },
    };
  },
  watch: {
    'disciplina.disciplinaId': 'preencherCargaHoraria',
    disciplinas: 'enviarDisciplina',
  },
  props: {
    form: { type: Object, default: Object },
  },
  mounted() {
    this.getdisciplina();
    this.getTipoMedia();

    if (this.form.id) this.disciplinas = this.form.disciplinas;
  },
  methods: {
    preencherCargaHoraria(value) {
      if (value !== '') {
        const cargaHoraria = this.opcoes.disciplina.filter(
          ({ id }) => id === value
        );
        this.disciplina.cargaHoraria = cargaHoraria[0].cargaHorario;
      }
    },

    filtrarDisciplinasAdicionadas(data) {
      let disciplinasFiltradas = [];
      data.forEach((item, index) => {
        let resultado = [];

        this.disciplinas.forEach((item2) => {
          if (item2.disciplinaId === item.id) {
            resultado.push({ ...item, index });
          }
        });

        if (index !== resultado[0]?.index) {
          disciplinasFiltradas.push(item);
        }
      });
      return disciplinasFiltradas;
    },

    getdisciplina() {
      this.$store.dispatch(START_LOADING);
      disciplinasService
        .listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));

          this.opcoes.disciplina = this.filtrarDisciplinasAdicionadas(data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTipoMedia() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-media')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));

          this.opcoes.media = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    adicionarLocalMente() {
      const mediaDescricao = this.opcoes.media.filter(
        ({ id }) => id === this.disciplina.tipoMediaEnum
      );
      this.disciplina.tipoMediaEnumDescricao = mediaDescricao[0].descricao;
      const disciplinaDescricao = this.opcoes.disciplina.filter(
        ({ id }) => id === this.disciplina.disciplinaId
      );
      this.disciplina.disciplinaDescricao = disciplinaDescricao[0].descricao;
      this.disciplina.codigo = disciplinaDescricao[0].codigo;

      this.disciplinas.push(this.disciplina);
      this.getdisciplina();
      this.disciplina = {
        disciplinaId: '',
        tipoMediaEnum: '',
        cargaHoraria: '',
      };
    },

    adicionarBackEnd() {
      this.$store.dispatch(START_LOADING);
      disciplinasService
        .criarTurmaDisciplina({ ...this.disciplina, turmaId: this.form.id })
        .then(({ data }) => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Disciplina criada com sucesso')
          );
          this.disciplinas = data;

          this.$emit('atualizar');

          this.getdisciplina();
          this.disciplina = {
            disciplinaId: '',
            tipoMediaEnum: '',
            cargaHoraria: '',
          };
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    adicionar() {
      if (!this.validarFormulario()) return;
      if (!this.form.id) {
        this.adicionarLocalMente();
        return;
      }
      this.adicionarBackEnd();
    },

    removerBack(item) {
      this.$store.dispatch(START_LOADING);
      disciplinasService
        .excluirTurmaDisciplina({ id: item.item.id, turmaId: this.form.id })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('Disciplina removida com sucesso')
          );
          this.disciplinas.splice(item.index, 1);
          this.getdisciplina();
          this.$emit('atualizar');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    remover(item) {
      mensagem
        .confirmacao(null, this.$t('Deseja remover disciplina ?'))
        .then(async (result) => {
          if (result.value) {
            if (item.item.id) {
              this.removerBack(item);
              return;
            }
            this.disciplinas.splice(item.index, 1);
            this.getdisciplina();
          }
        });
    },

    enviarDisciplina() {
      this.$emit('disciplinas', this.disciplinas);
    },
  },
};
</script>
<style>
.alinhamento-botao {
  padding-top: 28px;
}
</style>