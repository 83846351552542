<template>
  <b-table
    :items="turmas"
    :fields="fields"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    responsive
    striped
    hover
  >
    <template #cell(acoes)="item">
      <div class="flex">
        <b-dropdown variant="outline-default">
          <!-- <b-dropdown-item @click="irPara('academico-turma-x', item.item.id)">
                        {{ $t("GERAL.VISUALIZAR")}}
                    </b-dropdown-item> -->
          <b-dropdown-item @click="editar(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="excluir(item.item.id)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

export default {
  props: {
    turmas: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricao',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'cargaHoraria',
          label: 'Carga Horária',
          sortable: true,
        },
        {
          key: 'vagas',
          label: 'Vagas',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    editar(item) {
      this.$emit('editar', 'turma', item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
