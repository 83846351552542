<template>
  <modal
    id="modalTurma"
    :exibir="exibir"
    :titulo="'Cadastrar turma'"
    :campos="$refs"
    :tamanho="'xl'"
    @fechar="fecharModal"
  >
    <!-- dados de cadastro -->
    <div>
      <titulo title="Dados de cadastro:" />
      <turma-form :form="form" @refs="capturarRefs" />
    </div>

    <!-- adicionar disciplinas à turma -->
    <div>
      <titulo title="Adicione disciplinas à turma:" class="mt-3" />
      <form-disciplina
        @disciplinas="disciplinaForm"
        :form="form"
        @atualizar="atualizar"
      />
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import TurmasService from '@/common/services/academico/turma.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import TurmaForm from './Form';
import FormDisciplina from './FormDisciplina';
import Titulo from '@/components/headers/Titulo.vue';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    filtro: { type: Object, default: Object },
  },
  components: {
    Modal,
    TurmaForm,
    FormDisciplina,
    Titulo,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES CRUD
    atualizar() {
      this.$emit('atualiza-tabela');
    },
    disciplinaForm(item) {
      this.form.disciplinas = item;
    },
    salvar() {
      if (!this.form.id) {
        this.form.cursoId = this.filtro.cursoId;
        this.form.grupoTurmaId = this.filtro.grupoTurmaId;
        return this.criar();
      }
      return this.editar();
    },
    criar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      TurmasService.criar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('ACADEMICO.TURMA_CADASTRADA')
          );
          this.$emit('fechar');
        })
        .catch((err) => {
          mensagem.showErrors(err.response.data.errors);
        })
        .finally(() => {
          this.$emit('atualiza-tabela');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      TurmasService.editar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('ACADEMICO.TURMA_EDITADA')
          );
          this.$emit('fechar');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$emit('atualiza-tabela');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    validarFormulario() {
      const totalCargaHoraria = this.form.disciplinas
        .map((disciplina) => parseInt(disciplina.cargaHoraria))
        .reduce((acc, cargaHoraria) => acc + cargaHoraria, 0);
      if (totalCargaHoraria > this.form.cargaHoraria) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t(
            'A soma da Carga Horária das disciplinas está maior que a Carga Horária da turma'
          )
        );
        return;
      }
      return helpers.validarFormulario(this.refs);
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
  },
};
</script>
